<template lang="pug">
  v-container
    FlashMessage(:position="'left bottom'", :strategy="'multiple'")
    div(v-if="searchClient==null || isLoading==true").text-center.mt-4
      v-progress-circular(
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ).mt-15
    ais-instant-search(
      :search-client="searchClient",
      index-name="csUsers2",
      v-else
      :key="componentKey"
      )
      v-row
        v-col(cols="6")
          ais-pagination
            div(slot-scope="{refine}")
              v-text-field(
                @focus="refine(0)"
                placeholder="Search",
                solo,
                dense,
                prepend-inner-icon="mdi-magnify",
                clearable,
                single-line,
                v-model="search"
              )
        v-col(cols="2")
          v-btn(
            color="primary"
            @click="unblockDialog = true"
          ).py-5.block.ml-9
            v-icon.mr-2 mdi-block-helper
            span Unblock All
        v-col(cols="2")
          v-btn(
            color="error"
            @click="blockDialog = true"
          ).py-5.block.ml-9
            v-icon.mr-2 mdi-block-helper
            span Block All
        v-col(cols="2").ml-n5
          v-btn(
            color="green"
            dark
            @click="resetDialog = true"
          ).py-5.block.ml-9
            v-icon.mr-2 mdi-account-convert
            span Reset All

      ais-configure(
        :query="search",
        hitsPerPage = 10
        :facetFilters="['valid:true', 'waiting:false']"
      )
      ais-hits(ref="aisHit")
        div(slot-scope="{ items }")
          v-data-table.elevation-1(
            :headers="headers",
            :items="items",
            disable-pagination
            hide-default-footer
            expand-icon="mdi-information"
            show-expand
            single-expand
            :expanded.sync="expanded"
            :item-class="itemRowBackground"
          )
            template(v-slot:item.number="{ item }")
              div.text-center.text-muted.ml-n5(v-if="item.disabled == true") {{ item.number }}
              div.text-center.ml-n5(v-else) {{ item.number }}
            template(v-slot:item.email="{ item }")
              div.text-muted.ml-n5(v-if="item.disabled == true") {{ item.email }}
              div.ml-n5(v-else) {{ item.email }}
            template(v-slot:item.status="{ item }")
              div.text-muted.text-center.ml-n3(v-if="item.disabled == true") Disabled
              div.ml-n3(v-else-if="item.courses && item.courses.length==0") For Renewal
              div.ml-n3(v-else).text-center Enabled
            template(v-slot:item.type="{ item }")
              div.ml-n3(v-if="item.disabled == true").text-muted
                div(v-if="item.admin == true") Admin
                div(v-else-if="item.type == 'faculty'").text-capitalize Faculty
                div(v-else).text-capitalize {{ item.type }}
              div.ml-n3(v-else)
                div(v-if="item.admin == true") Admin
                div(v-else-if="item.type == 'faculty'").text-capitalize Faculty
                div(v-else).text-capitalize {{ item.type }}
            template(v-slot:item.set="{ item }")
              tooltipBtn
                template(v-slot:tooltip-content)
                  span(v-if="item.disabled==true") Blocked account's role cannot be change
                  span(v-else-if="item.admin && item.admin==true") Remove admin privileges
                  span(v-else) Give admin privileges
                template(v-slot:content)
                  v-btn(
                    small,
                    elevation="0",
                    :dark="!item.disabled"
                    color="indigo",
                    @click="setToUserPrompt(item.id, item.email); index = item.id",
                    :loading="loading && item.id == index",
                    :disabled="item.disabled==true"
                    v-if="item.admin"
                  ) Set to User
                  v-btn(
                    small,
                    elevation="0",
                    :dark="!item.disabled"
                    color="grey",
                    @click="adminBox(item.id, item.type, item.email); index = item.id",
                    v-else
                    :loading="loading && item.id == index"
                    :disabled="item.disabled==true"
                  ) Set to Admin
            template(v-slot:item.reset="{ item }")
              tooltipBtn
                template(v-slot:tooltip-content)
                  div.text-center
                    span(v-if="item.disabled==true") Blocked account cannot be reset
                    span(v-else-if="item.admin==true") Admin account cannot be reset
                    span(v-else-if="item.type=='faculty'") Faculty account cannot be reset
                    span(v-else-if="item.courses && item.courses.length==0") Reset already, user needs to fill up renewal form
                    span(v-else) Reset <strong>{{item.email}}</strong> for renewal
                template(v-slot:content)
                  v-btn(
                    color="success",
                    small,
                    elevation="0",
                    :loading="loading23",
                    @click="methodArgs = item; resDialog = true",
                    :disabled="item.admin==true || item.type == 'faculty' || item.disabled==true || (item.courses && item.courses.length==0)"
                  ) Reset
            template(v-slot:item.block="{ item }")
              tooltipBtn
                template(v-slot:tooltip-content)
                  div.text-center
                    span(v-if="item.admin==true") Admin account cannot be blocked
                    span(v-else-if="item.type=='faculty'") Faculty account cannot be blocked
                    span(v-else-if="item.disabled==true") Give back <strong>{{item.email}}</strong>'s access
                    span(v-else).text-center Block <strong>{{item.email}}</strong>'s access but retain its record
                template(v-slot:content)
                  v-btn(
                    v-if="item.disabled",
                    color="primary",
                    small,
                    elevation="0",
                    :loading="loading1 && item.id == index",
                    @click="unblockUser(item.id, item.email); index = item.id"
                  ) Unblock
                  v-btn(
                    v-else
                    color="error",
                    small,
                    elevation="0",
                    :loading="loading2 && item.id == index",
                    @click="blockUser(item.id, item.admin, item.email); index = item.id"
                    :disabled="item.admin==true || item.type=='faculty'"
                  ) Block
            template(v-slot:item.delete="{ item }")
              tooltipBtn
                template(v-slot:tooltip-content).text-center
                  div.text-center
                    span(v-if="item.admin==true") Admin account cannot be deleted. Set this to user first to delete
                    span(v-else) Remove <strong>{{item.email}}</strong>'s record from the system
                template(v-slot:content)
                  v-btn(
                    color="#8b0000",
                    small,
                    elevation="0",
                    :dark="!item.admin"
                    :loading="loading22",
                    @click="methodArgs = item; delDialog = true",
                    :disabled="item.admin==true"
                  ) Delete
            template(v-slot:expanded-item="{ headers, item }")
              td(:colspan="headers.length")
                v-row(justify="center").ma-1.text-center
                  v-col(cols="3")
                    span.caption Name
                    div.subtitle-2 {{ item.name }}
                  v-col(cols="4")
                    span.caption Institute
                    div(v-if="item.institution").subtitle-2
                      span {{ item.institution }}
                    div(v-else).text-muted No institute indicated yet
                  v-col(cols="5")
                    span.caption Courses
                    div(v-if="item.courses && item.courses.length!=0")
                      span.subtitle-2.mx-1(v-for="course in item.courses") {{course}}
                    div(v-else-if="item.courses && item.courses.length==0") For Renewal
                    div(v-else).text-muted No courses indicated yet
      ais-pagination(pages="5")
        div(
          slot-scope="{currentRefinement, nbPages,pages,isFirstPage,isLastPage,refine,createURL}"
        )
          .text-center.mt-5(v-if="pages.length >= 2")
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="!isFirstPage",
              @click.prevent="refine(0)",
              outlined
            )
              v-icon mdi-chevron-double-left
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              @click.prevent="refine(currentRefinement - 1)",
              outlined
            )
              v-icon mdi-menu-left
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-for="page in pages",
              :key="page",
              :outlined="page != currentRefinement",
              @click.prevent="refine(page)"
            ) {{ page + 1 }}
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="!isLastPage",
              @click.prevent="refine(currentRefinement + 1)",
              outlined
            )
              v-icon mdi-menu-right
            v-btn.mx-1(
              color="primary",
              x-small,
              fab,
              v-if="!isLastPage",
              @click.prevent="refine(nbPages)",
              outlined
            )
              v-icon mdi-chevron-double-right


    // blockAllAccounts Dialog
    PasswordPrompt(
      title="Block All Accounts",
      message="Are you sure that you want to block <b>ALL accounts</b>?",
      :model="blockDialog",
      :loading="passwordPromptButtonLoading"
      @password-ok="blockAll",
      @closed="blockDialog = false")

    // unblockAllAccounts Dialog
    PasswordPrompt(
      title="Unblock All Accounts",
      message="Are you sure that you want to unblock <b>ALL accounts</b>?",
      :model="unblockDialog",
      :loading="passwordPromptButtonLoading"
      @password-ok="unblockAll",
      @closed="unblockDialog = false")

    // resetAllAccounts Dialog
    PasswordPrompt(
      title="Reset All Accounts",
      message="Are you sure that you want to reset <b>ALL accounts</b>?",
      :model="resetDialog",
      :loading="passwordPromptButtonLoading",
      @password-ok="batchRct"
      @closed="resetDialog = false")

    // delDialog
    PasswordPrompt(
      title="Account Deletion",
      message="Are you sure that you want to delete <strong>this account?</strong>",
      :model="delDialog",
      event="delete-account",
      :loading="passwordPromptButtonLoading",
      @password-ok="deleteUser(methodArgs.id, methodArgs.email)",
      @closed="delDialog = false")

    // account reset Dialog
    PasswordPrompt(
      title="Account Reset",
      message="Are you sure that you want to reset <strong>this account?</strong>",
      :model="resDialog",
      :loading="passwordPromptButtonLoading",
      @password-ok="resetUser(methodArgs.id, methodArgs.email)",
      @closed="resDialog = false")

    // validate as admin ialog
    PasswordPrompt(
      title="Validate as Admin",
      :model="adminDialog",
      :loading="passwordPromptButtonLoading",
      @password-ok="addAdminRole(userId, userType, userEmail)",
      @closed="adminDialog = false")

    // block Admin
    PasswordPrompt(title="Block Admin",
      message="You cannot block an <strong>Admin</strong> account! If you want to block this account, set the role of this account from <strong>Admin</strong> to <strong>User</strong>."
      :model="blockAdmin",
      @closed="blockAdmin=false")

    // Set to User Dialog
    PasswordPrompt(
      title="Set to User",
      message="Are you sure that you want to make <strong>this account</strong> a user?",
      :model="setToUserDialog",
      :loading="passwordPromptButtonLoading",
      @password-ok="validateUser(userId, userEmail)",
      @closed="setToUserDialog = false")
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import PasswordPrompt from "@/components/PasswordPrompt.vue";
import tooltipBtn from "../components/TooltipBtn.vue";

let blockAll = firebase.functions().httpsCallable("blockAll");
let unblockAll = firebase.functions().httpsCallable("unblockAll");
let batchRct = firebase.functions().httpsCallable("batchRct");
let blockUser = firebase.functions().httpsCallable("blockUser");
let unblockUser = firebase.functions().httpsCallable("unblockUser");
let validateUser = firebase.functions().httpsCallable("validateUser");
let addAdminRole = firebase.functions().httpsCallable("addAdminRole");
let resetCoursesTaken = firebase.functions().httpsCallable("resetCoursesTaken");
let deleteUser = firebase.functions().httpsCallable("deleteUser");

export default {
  components: { PasswordPrompt, tooltipBtn },
  data() {
    return {
      expanded: [],
      searchClient: null,
      // methodArgs is used to store arguments to be used for the firebase methods
      methodArgs: {},
      // unified bool value to check whether a button is loading in prompts that check for password
      passwordPromptButtonLoading: false,
      loading: false,
      loading1: false,
      loading2: false,
      loading22: false,
      loading23: false,
      setToUserDialog: false,
      blockDialog: false,
      unblockDialog: false,
      resetDialog: false,
      adminDialog: false,
      blockAdmin: false,
      delDialog: false,
      resDialog: false,
      userId: "",
      userType: "",
      userEmail: "",
      show: false,
      user: "",
      adminPassword: "",
      search: "",
      limit: 10,
      lastVisible: "",
      firstVisible: "",
      next_btn: false,
      prev_btn: false,
      collection: "users",
      order: "__name__",
      field: "valid",
      equality: "==",
      value: true,
      headers: [
        {
          text: "Number",
          align: "center",
          value: "number",
          sortable: false,
        },
        {
          text: "E-mail",
          align: "center",
          value: "email",
        },
        {
          text: "Type",
          align: "center",
          value: "type",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          sortable: false,
        },
        {
          text: "Change Role",
          align: "center",
          value: "set",
          sortable: false,
        },
        {
          text: "Reset Access",
          align: "center",
          value: "reset",
          sortable: false,
        },
        {
          text: "Block/Unblock",
          align: "center",
          value: "block",
          sortable: false,
        },
        {
          text: "Delete",
          align: "center",
          value: "delete",
          sortable: false,
        },
      ],
      componentKey: 0,
      isLoading: false,
    };
  },

  mounted() {
    this.flashMessage.setStrategy("multiple");
    this.loadTypesense();
  },

  methods: {
    reloadSearch() {
      this.isLoading = true;
      setTimeout(() => {
        this.componentKey += 1;
        this.isLoading = false;
      }, 2000);
      this.search = "";
    },

    itemRowBackground: function (item) {
      if (item.disabled == true) return "style-1";
      if (item.courses && item.courses.length == 0) return "style-2";
    },
    loadTypesense() {
      this.searchClient = null;
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: "9QA76PeXsGdUiQ4Wi0Yrqcw2ougXot0N", // Be sure to use an API key that only allows search operations
          // apiKey: "ZvU5apnwttha8KTbcEskXZcHeIyRF515", // Be sure to use an API key that only allows search operations
          nodes: [
            {
              host: "ehubstorage.cslib.upd.edu.ph",
              port: "443",
              protocol: "https",
            },
          ],
          cacheSearchResultsForSeconds: 0, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
        },
        additionalSearchParameters: {
          queryBy: "name, number, email",
          sortBy: "admin:desc",
          filter_by: "admin:true",
        },
      });
      this.searchClient = typesenseInstantsearchAdapter.searchClient;
    },
    async blockAll() {
      this.passwordPromptButtonLoading = true;

      await blockAll().then(() => {
        this.flashMessage.success({
          title: "Blocked All Users",
          message: "You successfully blocked all non-admin users.",
        });
      });

      this.blockDialog = false;
      this.passwordPromptButtonLoading = false;
    },
    async unblockAll() {
      this.passwordPromptButtonLoading = true;

      await unblockAll();

      this.unblockDialog = false;
      this.passwordPromptButtonLoading = false;

      this.reloadSearch();
    },
    async batchRct() {
      this.passwordPromptButtonLoading = true;
      this.resetDialog = true;

      await batchRct().then(() => {
        this.flashMessage.success({
          title: "Reset All Users",
          message: "You successfully reset all non-admin users.",
        });
      });

      this.resetDialog = false;
      this.passwordPromptButtonLoading = false;
    },
    async blockUser(uid, admin, email) {
      this.loading2 = true;
      if (admin == true) {
        this.blockAdmin = true;
        // alert("You cannot block an Admin");
        this.loading2 = false;
      } else {
        await blockUser({ uid }).then(() => {
          this.flashMessage.error({
            title: "User Was Blocked",
            message:
              "You successfully removed the access of user " + email + "!",
            icon: "https://png.pngtree.com/png-vector/20190916/ourmid/pngtree-block-icon-for-your-project-png-image_1731069.jpg",
          });
          this.loading2 = false;
          this.reloadSearch();
        });
      }
    },

    async unblockUser(uid, email) {
      this.loading1 = true;
      await unblockUser({ uid }).then(() => {
        this.flashMessage.success({
          title: "User Was Unblocked",
          message:
            "You successfully unblocked the access of user " + email + "!",
          icon: "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
        });
        this.loading1 = false;
        this.reloadSearch();
      });
    },

    async validateUser(uid, email) {
      this.loading = true;
      this.passwordPromptButtonLoading = true;
      await validateUser({ uid }).then(() => {
        this.loading = false;
        this.flashMessage.success({
          title: "Set Admin to User Successfully",
          message:
            "You successfully changed " +
            email +
            "'s access from admin to user!",
          icon: "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
        });
        this.reloadSearch();
        this.setToUserDialog = false;
        this.passwordPromptButtonLoading = false;
      });
    },

    async resetUser(uid, email) {
      console.log(uid);
      this.passwordPromptButtonLoading = true;
      this.resDialog = true;
      await resetCoursesTaken({ id: uid }).then(() => {
        this.passwordPromptButtonLoading = false;
        this.flashMessage.success({
          title: "Reset User Successfully",
          message: "You have successfully resetted " + email,
        });
        this.reloadSearch();
        this.resDialog = false;
      });
    },

    async deleteUser(id, email) {
      this.passwordPromptButtonLoading = true;
      this.delDialog = true;
      await deleteUser({ id: id }).then(() => {
        this.flashMessage.error({
          title: "User Was Deleted",
          message: "You successfully deleted the account " + email,
          icon: "https://png.pngtree.com/png-vector/20190916/ourmid/pngtree-block-icon-for-your-project-png-image_1731069.jpg",
        });
        this.delDialog = false;
        this.passwordPromptButtonLoading = false;
        this.reloadSearch();
      });
    },

    async adminBox(id, type, email) {
      this.loading = true;
      this.userId = id;
      this.userType = type;
      this.userEmail = email;
      this.adminDialog = true;
    },

    async setToUserPrompt(id, type, email) {
      this.loading = true;
      this.userId = id;
      this.userType = type;
      this.userEmail = email;
      this.setToUserDialog = true;
    },

    async addAdminRole(uid, type, email) {
      this.loading = true;
      this.passwordPromptButtonLoading = true;
      await addAdminRole({ uid }).then(() => {
        this.loading = false;
        this.flashMessage.success({
          title: "Set User to Admin Successfully",
          message:
            "You successfully change " +
            email +
            "'s access from user to admin!",
          icon: "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
        });
        this.reloadSearch();
      });
      this.passwordPromptButtonLoading = false;
      this.adminDialog = false;
    },
  },
};
</script>
<style>
.v-data-table-header {
  margin-bottom: 5px !important;
  background: rgba(2, 0, 115, 0.8) !important;
  color: white !important;
}

th.text-center {
  color: white !important;
}

.add {
  margin-left: 3%;
}

.block {
  margin-top: -0.05em;
}

.v-card {
  z-index: 0;
}

._vue-flash-msg-body {
  z-index: 99 !important;
}

.vue-flash-msg-body__content {
  padding: 5px !important;
  z-index: 99 !important;
}

._vue-flash-msg-body__title {
  font-weight: bold;
  margin-top: 9px;
  padding-right: 10px;
  font-size: 20px;
  font-family: sans-serif;
  z-index: 99 !important;
}

._vue-flash-msg-body__text {
  margin-top: -15px;
  padding-right: 10px;
  font-family: sans-serif;
  z-index: 99 !important;
}

.style-1 {
  background-color: rgb(211, 208, 208);
  border-left: 2px solid #64646a;
}

.style-2 {
  background-color: rgb(155, 142, 204);
  border-left: 2px solid #010073;
}
</style>
